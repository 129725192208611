import React, { useContext } from 'react';
import SVG from 'react-inlinesvg';
import StoreContext from '~/context/StoreContext';
import styles from './styles.module.scss';
import caretUp from '~/images/caret-up.svg';
import caretDown from '~/images/caret-down.svg';

const QuantitySelector = ({ qty, onIncrease, onDecrease }) => {
  return (
    <div className={styles.selector}>
      <button title="Increase Quantity" onClick={onIncrease}><SVG src={caretUp} /></button>
      {qty}
      {qty > 1 && <button title="Decrease Quantity" onClick={onDecrease}><SVG src={caretDown} /></button>}
      {qty === 1 && <button title="Decrease Quantity" disabled><SVG src={caretDown} /></button>}
    </div>
  );
}

const LineItem = props => {
  const { line_item } = props
  const {
    removeLineItem,
    updateLineItem,
    store: { client, checkout },
  } = useContext(StoreContext)

  const variantImage = line_item.variant.image ? (
    <img
      src={line_item.variant.image.src}
      alt={`${line_item.title} product shot`}
      height="60px"
    />
  ) : null

  const selectedOptions = line_item.variant.selectedOptions
    ? line_item.variant.selectedOptions.map(
        option => `${option.name}: ${option.value} `
      )
    : null

  const handleRemove = () => {
    removeLineItem(client, checkout.id, line_item.id)
  }

  const handleIncrease = () => {
    const qty = line_item.quantity + 1;

    updateLineItem(client, checkout.id, line_item.id, qty);
  }

  const handleDecrease = () => {
    const qty = Math.max(1, line_item.quantity - 1);

    updateLineItem(client, checkout.id, line_item.id, qty);
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.image}>
        {variantImage}
      </div>
      <div className={styles.product}>
        {line_item.title}
        {`  `}
        {line_item.variant.title === !'Default Title'
          ? line_item.variant.title
          : ''}
        <i>{selectedOptions}</i>
        <a className={styles.remove} onClick={handleRemove} title="Remove">remove</a>
      </div>
      <div className={styles.quantity}>
        <QuantitySelector qty={line_item.quantity} onIncrease={handleIncrease} onDecrease={handleDecrease} />
      </div>
    </div>
  )
}

export default LineItem
