import React, {Component} from 'react';
import PropTypes from 'prop-types';

export default class FixedBottom extends Component {
  static propTypes = {
    children: PropTypes.element.isRequired,
  };

  state = {
    bottom: 0,
  };

  // Embrace React
  anchorRef = React.createRef();

  constructor(props) {
    super(props);
    // We don't want the framerate to crash, do we?
  }

  componentDidMount() {
    this.computeOffsetBottom();
    window.addEventListener('scroll', this.computeOffsetBottom);
  }

  componentWillUnmount() {
    // Throttled calls may be scheduled before the component unmounts
    window.removeEventListener('scroll', this.computeOffsetBottom);
  }

  computeOffsetBottom = () => {
    if (!this.anchorRef.current) {
      return;
    }

    const {bottom} = this.anchorRef.current.getBoundingClientRect();
    if (Math.floor(bottom) > window.innerHeight) {
      let menuHeight = Math.floor(bottom) - window.innerHeight;
      if (window.pageYOffset < 0) menuHeight += window.pageYOffset;
      this.setState({bottom:  menuHeight});
    } else {
      window.removeEventListener('scroll', this.computeOffsetBottom);
      this.setState({bottom: 0});
    }
  };

  render() {
    const {bottom} = this.state;
    const {children} = this.props;
    const node = React.cloneElement(React.Children.only(children), {
      style: {
        ...children.props.style,
        bottom,
      },
    });
    return (
      <>
        {node}
        {/* This div is used to run compute the offset without adding a ref */}
        {/* on the rendered children */}
        <div
          ref={this.anchorRef}
          style={{
            position: 'fixed',
            bottom: 0,
          }}
        />
      </>
    );
  }
}
