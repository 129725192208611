import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import './index.scss';
import styles from './styles.module.scss';

import ContextProvider from '~/provider/ContextProvider'
import Cart from '~/components/Cart'

import Navigation from '~/components/Navigation'

const Layout = ({ children }) => {
  return (
    <ContextProvider>
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={data => (
          <div className={styles.layoutWrapper}>
            <Navigation siteTitle={data.site.siteMetadata.title} />
            <div className={styles.wrapper}>
              {children}
            </div>
            <div className={styles.footer}>
              <footer>
                © {new Date().getFullYear()}, Pop Local
              </footer>
            </div>
            <Cart />
          </div>
        )}
      />
    </ContextProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
