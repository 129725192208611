import React, { useContext } from 'react'

import SVG from 'react-inlinesvg';
import StoreContext from '~/context/StoreContext'
import FixedBottom from '~/components/FixedBottom';
import LineItem from './LineItem'
import styles from './styles.module.scss';
import times from '~/images/times.svg';

const Cart = () => {
  const {
    store: { checkout, cart },
    showCart,
  } = useContext(StoreContext)

  const handleCheckout = () => {
    window.open(checkout.webUrl)
  }

  const line_items = checkout.lineItems.map(line_item => {
    return <LineItem key={line_item.id.toString()} line_item={line_item} />
  })

  const handleClose = () => {
    showCart(false);
  };

  return (
    <FixedBottom>
      <div className={styles.container} style={{ display: cart ? 'flex' : 'none' }}>
        <div className={styles.close} onClick={handleClose}><SVG src={times} /></div>
        <div className={styles.items}>
          {checkout.lineItems.length === 0 && <em className={styles.empty}>You have no items in your cart.</em>}
          {line_items}
        </div>
        {checkout.lineItems.length > 0 && <div className={styles.totals}>
          <section>
            <span>Subtotal</span>
            <span>${checkout.subtotalPrice}</span>
          </section>
          <section>
            <span>Taxes</span>
            <span>${checkout.totalTax}</span>
          </section>
          <section>
            <span>Total</span>
            <span>${checkout.totalPrice}</span>
          </section>
        </div>}
        <button className={styles.checkout} onClick={handleCheckout} disabled={checkout.lineItems.length === 0}>Check out</button>
      </div>
    </FixedBottom>
  )
}

export default Cart
