import React, { useContext } from 'react';
import reduce from 'lodash/reduce';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import shoppingBag from '~/images/shopping-bag-solid.svg';
import styles from './styles.module.scss';

import StoreContext from '~/context/StoreContext';

const useQuantity = () => {
  const { store: {checkout} } = useContext(StoreContext)
  const items = checkout ? checkout.lineItems : []
  const total = reduce(items, (acc, item) => acc + item.quantity, 0)
  return [total !== 0, total]
}

const Navigation = ({ siteTitle }) => {
  const [hasItems, quantity] = useQuantity()
  const { showCart } = useContext(StoreContext)

  return(
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <Link className={styles.title} to='/'>
          {siteTitle}
        </Link>
        <div className={styles.cart} onClick={showCart}>
          {hasItems &&
            <span className={styles.counter}>
              {quantity}
            </span>
          }
          Cart <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="shopping-bag" class="svg-inline--fa fa-shopping-bag fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M352 160v-32C352 57.42 294.579 0 224 0 153.42 0 96 57.42 96 128v32H0v272c0 44.183 35.817 80 80 80h288c44.183 0 80-35.817 80-80V160h-96zm-192-32c0-35.29 28.71-64 64-64s64 28.71 64 64v32H160v-32zm160 120c-13.255 0-24-10.745-24-24s10.745-24 24-24 24 10.745 24 24-10.745 24-24 24zm-192 0c-13.255 0-24-10.745-24-24s10.745-24 24-24 24 10.745 24 24-10.745 24-24 24z"></path></svg>
        </div>
      </div>
    </div>
  )
}

Navigation.propTypes = {
  siteTitle: PropTypes.string,
}

Navigation.defaultProps = {
  siteTitle: ``,
}

export default Navigation
